<template>
<div class="main">
  <VxeOrgTree service="/sys/sysOrganization/treeQueryAuthCommon/communtiy" class="main_left" />
  <div class="main_right">
    <VxeBasicTable ref="xGrid" :gridOptions="gridOptions" :searchOptions="searchOptions" :defaultTrigger="false">
    </VxeBasicTable>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      currNode: {},
      loading: false,
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [{
              code: "xfAssetInto",
              name: "资产导入",
              status: "primary",
              events: {
                click: () => {
                  if (this.currNode.type == '1001') {
                    this.$refs.xGrid.importAllEvent('/communitys/importExcel/importAssetForWest', {
                      communityId: this.currNode.id,
                      communityName: this.currNode.name
                    });
                  } else {
                    this.$message.error('请选择小区')
                  }
                }
              }
            },
            {
              code: "xfFeeInto",
              name: "费用导入",
              status: "primary",
              events: {
                click: () => {
                  if (this.currNode.type == '1001') {
                    this.$refs.xGrid.importAllEvent('/order/importOrderExcel/importAsset', {
                      communityId: this.currNode.id,
                      organizationId: this.currNode.parentId
                    });
                  } else {
                    this.$message.error('请选择小区')
                  }
                }
              }
            },
            {
              code: "xfFeeExport",
              name: "资产导出",
              status: "primary",
              events: {
                click: () => {
                  // 西房资产导出
                  if (this.currNode.type == '1001') {
                    this.$refs.xGrid.customExportAllEvent('/communitys/importExcel/exportAssetForWest', {
                      communityId: this.currNode.id,
                    });
                  } else {
                    this.$message.error('请选择小区')
                  }
                }
              }
            },
          ],
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60,
          },
          {
            type: "checkbox",
            width: 60,
          },
          {
            field: "ownerName",
            title: "业主名称",
            minWidth: 120,
            editRender: {
              name: "$input",
            }
          },
          {
            field: "suite",
            title: "套户",
            minWidth: 120,
            editRender: {
              name: "$input",
            }
          },
          {
            field: "type",
            title: "项目类型",
            minWidth: 120,
            editRender: {
              name: "$input",
            }
          },
          {
            field: "roomBuiltUpArea",
            title: "建筑面积",
            minWidth: 120,
            editRender: {
              name: "$input",
            }
          },
          {
            field: "payFeeConfigName",
            title: "项目名称",
            minWidth: 120,
            editRender: {
              name: "$input",
            }
          },
          {
            field: "payType",
            title: "支付方式",
            minWidth: 120,
            editRender: {
              name: "$input",
            }
          },
          {
            field: "feeMonth",
            title: "计费期",
            minWidth: 120,
            editRender: {
              name: "$input",
            }
          },
          {
            field: "receivedAmount",
            title: "交易金额",
            minWidth: 120,
            editRender: {
              name: "$input",
            }
          },
          {
            field: "operatorName",
            title: "操作员",
            minWidth: 120,
            editRender: {
              name: "$input",
            }
          },
          {
            field: "createTime",
            title: "交易时间",
            minWidth: 120,
            editRender: {
              name: "$input",
            }
          },
          {
            field: "errorMessage",
            title: "错误信息",
            minWidth: 120,
            editRender: {
              name: "$input",
            }
          },
        ],
        pagerConfig: {
          enabled: false,
        },
        editConfig: {
          enabled: false
        },
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 110,
        items: [],
        //表单校验规则
        rules: {},
        // 新增功能字段初始化
        data: {},
      },
      // 查询功能字段配置
      searchOptions: {
        items: [],
        data: {},
      },
    };
  },

  methods: {
    handleNodeClick(treeData, node) {
      this.currNode = node
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
